<script>
import api from '@/command/api'
import moment from 'moment'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import { suitNum, refundExplainConfig } from '@/utils/textFile'
export default {
  name: 'farming',
  data() {
    return {
      ...api.command.getState(),
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/goods/farmFarmingExperience/page',
      current: 1,
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '名称',
          key: 'name',
          type: 'input',
        },
        {
          name: '店铺名称',
          type: 'input',
          key: 'mainBodyName',
        },
        {
          name: '权益-退款',
          type: 'select',
          key: 'refundExplain',
          typeData: refundExplainConfig,
        },
        {
          name: '权益-预约',
          type: 'select',
          key: 'subscribe',
          typeData: [
            {
              name: '免预约',
              value: '0',
            },
            {
              name: '电话预约',
              value: '1',
            },
          ],
        },
        {
          name: '现价',
          type: 'row',
          children: [
            {
              type: 'input',
              cols: 11,
              label: '最低现价',
              key: 'minPrice',
            },
            {
              type: 'text',
              value: '至',
              cols: 2,
              align: 'center',
            },
            {
              type: 'input',
              cols: 11,
              label: '最高现价',
              key: 'maxPrice',
            },
          ],
        },
        {
          name: '会员价',
          type: 'row',
          children: [
            {
              type: 'input',
              cols: 11,
              label: '最低会员价',
              key: 'minVipPrice',
            },
            {
              type: 'text',
              value: '至',
              cols: 2,
              align: 'center',
            },
            {
              type: 'input',
              cols: 11,
              label: '最高会员价',
              key: 'maxVipPrice',
            },
          ],
        },
        {
          name: 'ID',
          type: 'input',
          key: 'id',
        },
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id,
        },
        {
          dataIndex: 'name',
          title: '名称',
          align: 'left',
          width: 200,
          customRender: (text, records) => {
            return (
              <div class="product-order-item-copy">
                <img src={records.img.split(',')[0] || ''} />
                <div>{text}</div>
              </div>
            )
          },
          //   onExport: (text, records) => {
          //     return `${records.img} / ${text}`
          //   },
          sorter: (a, b) => a.name.length - b.name.length,
        },
        {
          dataIndex: 'mainBodyName',
          title: '店铺名称',
          align: 'left',
          sorter: (a, b) => a.mainBodyName.length - b.mainBodyName.length,
        },
        {
          dataIndex: 'salePrice',
          title: '现价',
          align: 'left',
          sorter: (a, b) => a.salePrice - b.salePrice,
          customRender: function (text, records) {
            return records.salePrice == null ? '' : <div>{'￥' + records.salePrice}</div>
          },
        },
        {
          dataIndex: 'vipPrice',
          title: '会员价',
          align: 'left',
          sorter: (a, b) => a.vipPrice - b.vipPrice,
          customRender: function (text, records) {
            return records.vipPrice == null ? '' : <div>{'￥' + records.vipPrice}</div>
          },
        },
        {
          dataIndex: 'suitNum',
          title: '适用人数',
          align: 'left',
          onExport: (text,records) => {
            return (suitNum().filter((e) => e.id == records?.suitNum) || [{ name: '' }])[0].name
          },
          filters: suitNum(),
          filterMultiple: false,
          customRender: function (text, records) {
            return (suitNum().filter((e) => e.id == records?.suitNum) || [{ name: '' }])[0].name
          },
        },
        {
          dataIndex: 'refundExplain',
          title: '权益',
          align: 'left',
          onExport: (text, records) => {
            return `${refundExplainConfig.filter((e) => e.value == records.refundExplain)[0].name}，${
              records.subscribe == '1' ? '电话预约' : '免预约'
            }`
          },
          getData: (records) => {
            return [
              refundExplainConfig.filter((e) => e.value == records.refundExplain)[0].name,
              records.subscribe == '1' ? '电话预约' : '免预约',
            ]
          },
          type: 'tagGroup',
        },
        {
          dataIndex: 'stockNum',
          title: '库存',
          align: 'left',
          sorter: (a, b) => a.stockNum - b.stockNum,
        },
        {
          dataIndex: 'startDate',
          title: '有效期',
          align: 'left',
          isId: true,
          sorter: (a, b) => moment(a.endDate).unix() - moment(b.endDate).unix(),
          customRender: function (text, records) {
            return records.startDate == null ? '' : <div>{records.startDate + '~' + records.endDate}</div>
          },
          onExport: (text, records) => {
            return `${records.startDate + '~' + records.endDate}`
          },
        },
        {
          dataIndex: 'upDown',
          title: '状态',
          type: 'badge',
          onExport: (records) => {
            return ['上架', '下架'][records]
          },
          filters: [
            {
              text: '上架',
              value: '0',
            },
            {
              text: '下架',
              value: '1',
            },
          ],
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '上架' : '下架',
              color: data == 0 ? 'green' : 'red',
            }
          },
        },
        {
          dataIndex: 'a9',
          title: '操作',
          type: 'buttonGroup',
          fixed: 'right',
          typeData: ({ records }) => {
            return [
              {
                name: records.upDown == 0 ? '下架' : '上架',
                onClick: () => {
                  api.command.editGET.call(this, {
                    url: `/api/goods/farmFarmingExperience/${records.upDown == 0 ? 'down' : 'up'}?id=${records.id}`,
                  })
                },
              },
              {
                name: '编辑',
                onClick: () => this.$router.push(`/commodityManagement/farmingDetail?id=${records.id}`),
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '是否确认删除?',
                onClick: () =>
                  api.command.del.call(this, {
                    url: `/goods/farmFarmingExperience?id=${records.id}`,
                  }),
              },
            ]
          },
        },
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.$router.push('/commodityManagement/farmingDetail?type=0'),
        },
        // {
        //   viewType: 'menu',
        //   name: '添加',
        //   type: 'primary',
        //   isBatch: false,
        //   children: [
        //     {
        //       name: '采摘',
        //       onClick: () => {
        //         this.$router.push('/commodityManagement/farmingDetail?type=0')
        //       }
        //     },
        //     {
        //       name: '垂钓',
        //       onClick: () => {
        //         this.$router.push('/commodityManagement/farmingDetail?type=1')
        //       }
        //     }
        //   ]
        // },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.delPut.call(this, {
                  url: '/goods/farmFarmingExperience/deleteBatch',
                  params: { idList },
                })
              },
            },
            {
              name: '批量上架',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.edit.call(this, {
                  url: '/goods/farmFarmingExperience/upBatch',
                  params: { idList },
                })
              },
            },
            {
              name: '批量下架',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.edit.call(this, {
                  url: '/goods/farmFarmingExperience/downBatch',
                  params: { idList },
                })
              },
            },
          ],
        },
      ]
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  },
}
</script>
